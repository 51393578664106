<template>
  <div>
    <div class="header">
      <div>
        <el-page-header @back="goBack" content="浏览记录"></el-page-header>
      </div>
    </div>
    <ListFilter :total="total" @close="putAway">
      关键字：
      <el-input
        placeholder="请输入关键字"
        v-model="formInline.keywords"
        @input="loadData()"
        size="mini"
        style="margin-right: 10px; width: 300px"
        prefix-icon="el-icon-search"
        clearable
      ></el-input>
    </ListFilter>
    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="访问者">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <img
              v-if="scope.row.viewUser"
              style="width: 40px; border-radius: 50%; margin-right: 10px"
              :src="scope.row.viewUser.headImage"
              alt
              srcset
            />
            <span class="clickColor">
              {{
                scope.row.viewUser && scope.row.viewUser.nickname
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="职位" prop="job.name"></el-table-column>
      <el-table-column label="企业" prop="job.enterpriseName"></el-table-column>
      <el-table-column label="推荐人" width="120" prop="recommendUser"></el-table-column>
      <el-table-column label="访问时间" prop="createTime"></el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const recruitEnlistList = api('/recruitEnlist')('recruitView.list.json');
export const recruitEnlistEdite = api('/recruitEnlist')(
  'recruitView.edit.json'
);

export default {
  components: {
    ListFilter: () => import('/src/components/filter')
  },
  data () {
    return {
      code: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {},
      list: []
    };
  },
  created () {
    this.code = this.$route.query.code;
    this.loadData();
  },
  methods: {
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        jobCode: this.code
      };
      recruitEnlistList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    async putAway () {
      this.pageNumber = 1;
      this.formInline.keywords = '';
      this.loadData();
    },

    async publish () {
      await recruitEnlistEdite({ state: '2', stateRemark: '' });
    },
    async goBack () {
      window.history.go(-1);
    }
  }
};
</script>

<style></style>
